import React, { useRef } from "react";
import * as Mui from "@material-ui/core";
import "../../css/passCode.css";
import { ToastContainer, toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import QRCode from "react-qr-code";
import { QRCodeCanvas, QRCodeSVG } from "qrcode.react";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
export default function ShareProduct(props) {
  const linkCopied = () => toast.success("Link copied!");
  const bothCopied = () => toast.success("Link and access copied!");
  const codeCopied = () => toast.success("Access code copied!");
  const qrRef = useRef();
  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      process.env.REACT_APP_FOLDER_URL +
        btoa(
          props.productID.split("").reverse().join("") + "," + props.productID
        )
    );
    // navigator.clipboard.writeText(process.env.REACT_APP_FOLDER_URL + props.url.replace(/ /g, '%20'))
    linkCopied();
  };
  const handleCopyBoth = () => {
    let text =
      "Course link: " +
      process.env.REACT_APP_FOLDER_URL +
      btoa(
        props.productID.split("").reverse().join("") + "," + props.productID
      ) +
      // let text = 'Course link: ' + process.env.REACT_APP_FOLDER_URL + props.url.replace(/ /g, '%20') +
      "\nAccess code: " +
      props.accessCode;
    navigator.clipboard.writeText(text);
    bothCopied();
  };
  const handleCopyCode = () => {
    navigator.clipboard.writeText(props.accessCode);
    codeCopied();
  };

  const handleShareWhatsapp = () => {
    let text =
      "URL:" +
      process.env.REACT_APP_FOLDER_URL +
      btoa(
        props.productID.split("").reverse().join("") + "," + props.productID
      ) +
      "%0aAccess code:" +
      props.accessCode;
    let url = "https://api.whatsapp.com/send?text=" + text;
    window.open(url, "_blank");
  };

  const QRCodeBox = ({ id, ref }) => {
    let url =
      process.env.REACT_APP_FOLDER_URL +
      "qr/" +
      btoa(
        props.productID.split("").reverse().join("") +
          "," +
          props.productID +
          "," +
          props.accessCode
      );
    return (
      <QRCodeCanvas
        bgColor="transparent"
        id={id}
        ref={ref}
        size={200}
        includeMargin={false}
        value={url}
      />
    );
  };

  const handleDownload = (id) => {
    const canvas = document.getElementById(id);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = id + ".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={2000} />
      <Mui.Dialog
        onBackdropClick={props.closeShare}
        maxWidth={"xs"}
        fullWidth={true}
        open={true}
      >
        <Mui.DialogTitle>Share this course</Mui.DialogTitle>
        <Mui.Icon onClick={props.closeShare} className="close-modal">
          close
        </Mui.Icon>
        <Mui.DialogContent>
          <Mui.Grid container spacing={3} justifyContent="center">
            <Mui.Tooltip
              title={<h2 style={{ margin: 5 }}>Share on whatsapp</h2>}
              placement="top"
            >
              <Mui.Grid item>
                <Mui.Icon
                  onClick={handleShareWhatsapp}
                  className="whats-icons share-icons"
                >
                  <WhatsAppIcon style={{fontSize:18}} />
                </Mui.Icon>
              </Mui.Grid>
            </Mui.Tooltip>

            <Mui.Tooltip
              title={<h2 style={{ margin: 5 }}>Copy URL</h2>}
              placement="top"
            >
              <Mui.Grid item>
                <Mui.Icon
                  onClick={handleCopyLink}
                  className="link-icons share-icons"
                >
                  link
                </Mui.Icon>
              </Mui.Grid>
            </Mui.Tooltip>
            <Mui.Tooltip
              title={<h2 style={{ margin: 5 }}>Copy access code</h2>}
              placement="top"
            >
              <Mui.Grid item>
                <Mui.Icon
                  onClick={handleCopyCode}
                  className="key-icons share-icons"
                >
                  key
                </Mui.Icon>
              </Mui.Grid>
            </Mui.Tooltip>
            <Mui.Tooltip
              title={<h2 style={{ margin: 5 }}>Copy URL and access code</h2>}
              placement="top"
            >
              <Mui.Grid item>
                <Mui.Icon
                  onClick={handleCopyBoth}
                  className="copy-icons share-icons"
                >
                  content_copy
                </Mui.Icon>
              </Mui.Grid>
            </Mui.Tooltip>
          </Mui.Grid>
          <Mui.Grid
            container
            alignItems="center"
            direction="column"
            justifyContent="center"
            style={{ padding: 10 }}
          >
            <Mui.Grid item>
              <QRCodeBox id={props.productID} ref={qrRef} />
            </Mui.Grid>
            <Mui.Grid item>
              <Mui.Button
                style={{ backgroundColor: "#10936f", color: "white" }}
                onClick={() => handleDownload(props.productID)}
              >
                <Mui.Icon style={{ marginRight: 5 }}>download</Mui.Icon>{" "}
                Download QR code
              </Mui.Button>
            </Mui.Grid>
          </Mui.Grid>
          <div className="url-input">
            {process.env.REACT_APP_FOLDER_URL +
              btoa(
                props.productID.split("").reverse().join("") +
                  "," +
                  props.productID
              )}
          </div>
        </Mui.DialogContent>
      </Mui.Dialog>
    </>
  );
}
